<template>
  <div class="md-news-pages-component">
    <div class="news-header"></div>
    <div class="md-news-panel">
      <ul class="news-list" v-if="articleList.length">
        <li
          v-for="(item, index) in articleList"
          :key="index"
          @click="handleTurnDetail(item)"
        >
          <div class="md-information-news">
            <img
              :src="`/video-dev${item.cover}`"
              alt=""
              style="width: 120px; height: 80px; border-radius: 6px"
            />
            <div class="news-right">
              <p>{{ item.title }}</p>
              <p>{{ item.title }}</p>
              <p>{{ /\d{4}-\d{1,2}-\d{1,2}/g.exec(item.createTime)[0] }}</p>
            </div>
          </div>
        </li>
      </ul>
      <p v-else class="no-data">暂无数据</p>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="+params.pageNum"
          :page-size="params.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { selectArticleList } from "@/axios/news";
import { getDictType } from "@/axios/home";
export default {
  name: "mdNewsPagesComponent",
  data() {
    return {
      params: {
        type1: 1,
        pageNum: 1,
        pageSize: 10,
      },
      articleList: [],
      dictList: [],
      total: 0,
    };
  },
  created() {
    this.selectArticleList();
    this.getDictType();
  },
  methods: {
    async selectArticleList() {
      const res = await selectArticleList(this.params);
      if (res.code === 200) {
        this.articleList = res.rows;
        this.total = res.total;
      }
    },
    async getDictType() {
      const res = await getDictType("md_news_type");
      if (res.code === 200) {
        this.dictList = res.data;
      }
    },
    handleTurnDetail(item) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          rid: item.rid,
          item: JSON.stringify(item),
        },
      });
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.selectArticleList();
    },
  },
};
</script>
<style lang="less" scoped>
.md-news-pages-component {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #fff;
  background-color: @md-bgc-primary-color;
  .md-news-panel {
    width: 1230px;
    margin: 0 auto;
    padding: 30px 0;
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px 20px 20px;
      background-color: #fff;

      li {
        width: 560px;
        margin-top: 20px;
        :hover {
          cursor: pointer;
        }
      }

      > :nth-child(even) {
        margin-left: 60px;
      }
    }
  }
  .news-header {
    width: 100%;
    height: 140px;
    background-image: url("../assets/img/home-bgi.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .news-list {
    width: 100%;
    border-radius: 0 0 8px 8px;

    .news-item {
      cursor: pointer;
      padding: 20px 0;
      border-bottom: 1px solid rgb(230, 230, 230);
      display: flex;
      align-items: center;

      &:hover {
        .t-tit {
          color: @md-primary-color;
        }
      }

      &:first-child {
        padding-top: 0;
      }

      .t-img {
        width: 100px;
        height: 100px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 20px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .t-right {
        flex: 1;
        height: 100px;
        display: flex;
        align-items: center;

        .t-tit-box {
          flex: 1;

          .t-tit {
            font-size: 16px;
            margin-bottom: 20px;
          }

          .t-type {
            span {
              color: #888;
              font-size: 12px;
              margin-right: 30px;
            }
          }
        }

        .q-btn {
          margin-left: 10px;
          flex-shrink: 0;
        }
      }
    }
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

.md-information-news {
  display: flex;
  align-items: center;

  .news-right {
    margin-left: 12px;

    > :nth-child(1) {
      font-size: 16px;
      font-family: PingFang-SC, PingFang-SC;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
    }

    > :nth-child(2) {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
      margin-top: 8px;
    }

    > :nth-child(3) {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      margin-top: 8px;
    }
  }
}

.no-data {
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
}
/deep/ .el-pager li.active {
  color: @md-primary-color;
}

/deep/ .el-pager li:hover {
  color: @md-primary-color;
}

/deep/ .el-input__inner:focus {
  border-color: @md-primary-color !important;
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: @md-primary-color;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    border: 1px solid #cccccc;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
  }
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    height: 36px;
    line-height: 36px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: @md-primary-color;
  }
}
</style>
